import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { isMobile } from "react-device-detect";

function Footer() {
  if (isMobile){
  return (
    <div className="footer-container">
    <div className="footer-links">
    <div className='footer-link-wrapper'>

      <Link to="/bathx" target={"_top"} className="footer-link-items">
        BathX™
      </Link>
      <Link to="/pillars" target={"_top"} className="footer-link-items">
        Seven Pillars
      </Link>
    </div>
    <div className='footer-link-wrapper'>

      <Link to="/about" target={"_top"} className="footer-link-items">
        About Us
      </Link>
      <Link to="/contact" target={"_top"} className="footer-link-items">
        Contact Us
      </Link>
    </div>
    </div>
      <Link to="/" target={"_top"}>
        <small class="website-rights">Copyright © pathx.us 2025</small>
      </Link>
    </div>
  
  );
  } else {
    return (
      <div className="footer-container">
      <Link to="/" target={"_top"}>
        <small class="website-rights">Copyright © pathx.us 2022</small>
      </Link>
    </div>
    )
  }
}

export default Footer;
